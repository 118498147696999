import { RoleChecker } from './RoleChecker';
import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CanLoadAdmin implements CanLoad {
  constructor() {}

  canLoad(route: Route, segments: UrlSegment[]) {
    return RoleChecker.isDoctor() || RoleChecker.isAdmin();
  }
}
