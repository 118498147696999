export class RoleChecker {
  public static isAdmin() {
    return this.hasRole('Administrateur');
  }

  public static isDoctor(): boolean {
    return this.hasRole('Equipe soignante');
  }

  private static hasRole(role: string): boolean {
    const jwt = localStorage.getItem('token');
    const jwtData = jwt.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);
    return decodedJwtData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].includes(role);
  }
}
