import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCacheInterceptor } from './base.cache.interceptor';
import { CONFIGURATION_NOTIFICATION_ENDPOINT } from '../services/configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationConfigurationCacheInterceptor extends BaseCacheInterceptor {

  protected getKey(req: HttpRequest<any>): string {
    return req.params.get('notificationKey');
  }

  protected isCachableRequest(req: HttpRequest<any>): boolean {
    return req.url.includes(`${CONFIGURATION_NOTIFICATION_ENDPOINT}/GetByConfigurationKey`);
  }
}
