import { BaseCacheInterceptor } from './base.cache.interceptor';
import { HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export class DefaultObjectsCacheInterceptor extends BaseCacheInterceptor {

  protected getKey(req: HttpRequest<any>): string {
    console.log(req.url.toLowerCase().replace(environment.apiUrl, ''));
    return req.url.toLowerCase().replace(environment.apiUrl, '');
  }

  protected isCachableRequest(req: HttpRequest<any>): boolean {
    return req.url.endsWith('/default') /*|| req.url.endsWith('/schema')*/;
  }
}
