import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Route, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CapcodClarityModule } from 'capcod-clarity';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { CanLoadAdmin } from 'src/app/guards/CanLoadAdmin';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { DefaultObjectsCacheInterceptor } from './interceptors/default-objects-cache.interceptor';
import { NotificationConfigurationCacheInterceptor } from './interceptors/notification-configuration-cache.interceptor';

registerLocaleData(localeFr);

const routes: Route[] = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  {
    path: 'onko-admin',
    loadChildren: () => import('./onko-admin/onko-admin.module')
      .then(m => m.OnkoAdminModule), canLoad: [CanLoadAdmin]
  },
  { path: '**', redirectTo: 'login' },
];
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    BrowserAnimationsModule,
    SnotifyModule,
    CapcodClarityModule.forRoot(environment),
    IonicModule.forRoot()
  ],
  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationConfigurationCacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultObjectsCacheInterceptor,
      multi: true
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
