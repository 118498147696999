import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { differenceInMilliseconds } from 'date-fns';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export abstract class BaseCacheInterceptor implements HttpInterceptor {

  protected cacheExpiryInMilliseconds = 2000;
  private cache: { [notificationKey: string]: { httpEvent: HttpEvent<any>, lastUpdate: Date } } = {};

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isCachableRequest(req)) {
      return next.handle(req);
    }

    const cachedResponse = this.getFromCache(req);
    return cachedResponse ? of(cachedResponse) : this.cacheRequest(req, next);
  }

  cacheRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(httpEvent => {
      const key = this.getKey(req);
      const lastUpdate = new Date();
      this.cache[key] = { httpEvent, lastUpdate };
    }));
  }

  getFromCache(req: HttpRequest<any>): HttpEvent<any> {
    const key = this.getKey(req);
    const cacheEntry = this.cache[key];
    if (cacheEntry && this.isCacheEntryStillValid(cacheEntry)) {
      return cacheEntry.httpEvent;
    }
    return this.cache[key] = undefined;
  }

  private isCacheEntryStillValid(httpEvent: { httpEvent: HttpEvent<any>; lastUpdate: Date; }) {
    const diff = differenceInMilliseconds(new Date(), httpEvent.lastUpdate);
    return diff < this.cacheExpiryInMilliseconds;
  }

  protected abstract isCachableRequest(req: HttpRequest<any>): boolean;
  protected abstract getKey(req: HttpRequest<any>): string;
}
