import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ClarityHttpService, CrudServiceGeneric } from 'capcod-core';
import { NotificationKey } from './notification-keys.enum';

@Injectable({
  providedIn: 'root'
})
export class NotificationConfigurationService extends CrudServiceGeneric {
  constructor(@Inject('env') protected environment, protected http: ClarityHttpService) {
    super(environment, http, 'configuration/notifications');
  }

  public getByConfigurationKey(notificationKey: NotificationKey) {
    const params = new HttpParams().set('notificationKey', notificationKey.toString());
    return this.http.get(`${this.baseurl}/GetByConfigurationKey`, { params });
  }
}

export const CONFIGURATION_NOTIFICATION_ENDPOINT = 'configuration/notifications';
